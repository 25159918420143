import { graphql } from 'gatsby';
import React from 'react';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Ragnar from '../src/components/Ragnar/Ragnar';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import logo_pa from '../src/images/logo-paw-43x34.png';
import './ficha_prensa.scss';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query prensaQuery($note: String!, $locale: String!) {
    allPrensaSeoBlock(filter: { lang: { eq: $locale }, tag: { eq: $note } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPrensaTitle(filter: { lang: { eq: $locale }, tag: { eq: $note } }) {
      edges {
        node {
          title
        }
      }
    }
    allPrensaTitleBlock(filter: { lang: { eq: $locale }, tag: { eq: $note } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allPrensaHeaderBlock(filter: { lang: { eq: $locale }, tag: { eq: $note } }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPrensaFilterBlock(
      filter: { lang: { eq: $locale }, tag: { eq: $note } }
      sort: { fields: link_copy, order: DESC }
    ) {
      edges {
        node {
          link_path
          link_copy
        }
      }
    }
    allPrensaBreadcrumbBlock(filter: { lang: { eq: $locale }, tag: { eq: $note } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allNoteListBlock(
      filter: { lang: { eq: $locale }, tag: { eq: $note } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          description
          characteristics
        }
      }
    }
    allPrensaRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class fichaPrensa extends React.Component {
  state = {};

  handleDropDownChange = (e) => {
    location.replace(`${e.target.value}`);
  };

  render() {
    const heimdallData = {
      name: this.props.data.allPrensaTitle.edges[0].node.title,
      image: {
        url: this.props.data.allPrensaHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const prensaSubtitle = {
      title: this.props.data.allPrensaTitleBlock.edges[0].node.description,
    };

    const actualPage = this.props.pageContext.options[0].split('-');
    const getNoteYear = actualPage[actualPage.length - 1];
    const noteList = this.props.data.allNoteListBlock.edges.filter(
      (element) => element.node.title.length
    );

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allPrensaSeoBlock.edges[0].node._0.title}
          description={this.props.data.allPrensaSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allPrensaRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allPrensaHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="prensa-container">
              <div className="filter-general-container">
                <div className="filter-image">
                  <img src={logo_pa} alt="logo" />
                </div>
                <div className="standard-box filter-container">
                  <div className="select-container">
                    <select onChange={this.handleDropDownChange} name="note" form="note-selector">
                      {this.props.data.allPrensaFilterBlock.edges.map((option) => {
                        if (option.node.link_copy < '2019') {
                          return (
                            <React.Fragment key={option.node.link_copy}>
                              {getNoteYear === option.node.link_copy ? (
                                <option
                                  value={`${option.node.link_path}`}
                                  selected
                                >{`${option.node.link_copy}`}</option>
                              ) : (
                                <option
                                  value={`${option.node.link_path}`}
                                >{`${option.node.link_copy}`}</option>
                              )}
                            </React.Fragment>
                          );
                        }
                        if (
                          this.props.pageContext.locale == 'es' ||
                          this.props.pageContext.locale == 'fr' ||
                          this.props.pageContext.locale == 'en' ||
                          this.props.pageContext.locale == 'ca' ||
                          this.props.pageContext.locale == 'ru'
                        ) {
                          return (
                            <React.Fragment key={option.node.link_copy}>
                              {getNoteYear === option.node.link_copy ? (
                                <option
                                  value={`${option.node.link_path}`}
                                  selected
                                >{`${option.node.link_copy}`}</option>
                              ) : (
                                <option
                                  value={`${option.node.link_path}`}
                                >{`${option.node.link_copy}`}</option>
                              )}
                            </React.Fragment>
                          );
                        }
                      })}
                    </select>
                  </div>
                  <div className="filter-text">{`${noteList.length} ${tt(
                    'NOTAS DE PRENSA',
                    this.props.pageContext.locale
                  )}`}</div>
                </div>
              </div>
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allPrensaBreadcrumbBlock.edges[0].node.name}
              />
              <H2 data={prensaSubtitle} />
              <Ragnar data={noteList} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default fichaPrensa;
